@font-face {
  font-family: 'Nunito Sans';
  font-weight: 500;
  font-style: normal;
  src: local('Nunito_Sans'),
  url('../fonts/NunitoSans.ttf') format('ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  font-weight: 700;
  font-style: normal;
  src: local('Nunito_Sans'),
  url('../fonts/NunitoSans.ttf') format('ttf');
  font-display: swap;
}
