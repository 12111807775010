@import "assets/scss/vars";
@import "assets/scss/fonts";
@import "assets/scss/reset";
@import "assets/scss/media";

@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

body {
  background: #ececec;
  margin-left: calc(100vw - 100%);
  margin-right: 0;
}

app-video {
  width: 100%;
  display: block;
  height: 100%;
}

.mpu-tooltip {
   a {
    color: var(--Blue-100, #28285e);
    text-decoration-line: underline;
  }
}

.mpu-tooltip.p-tooltip {

  .p-tooltip-text {
    background: #ffffff !important;
    font-size: 14px;
    font-weight: 500;
    padding: 10px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2196078431);
    color: #000;
  }
  .p-tooltip-arrow {
    color: white;
  }
}
.pi-chevron-up:before {
  content: "\2191";
  font-size: 35px;
}
.p-scrolltop {
  bottom: 90px !important;
  width: 60px;
  height: 60px;
}
.mpu-tooltip.p-tooltip-top {
  margin-top: -10px !important;
}
.mpu-tooltip.p-tooltip-right {
  margin-left: 10px;
}

a.link {
  color: var(--Blue-100, #28285e);
  text-decoration-line: underline;
  cursor: pointer;
}

a.link-no-color {
  cursor: pointer;
  text-decoration-line: underline;
}

.ng-image-slider .ng-image-slider-container .main {
  position: static !important;
  height: auto !important;
  .main-inner {
    align-items: center;
    display: flex;
  }
}
.ng-image-slider {
  height: auto !important;
}

.ng-image-slider .ng-image-slider-container .main .main-inner .img-div img {
  position: static !important;
}

.ng-image-slider .ng-image-slider-container .main .next {
  right: 40px !important;
  text-decoration: none;
  font-family: monospace;
}
.ng-image-slider .ng-image-slider-container .main .prev {
  font-family: monospace;
  left: 40px !important;
  text-decoration: none;
}
.inner-icon {
  margin-left: 10px;
}

.h9-text {
  //styleName: H9 Text;
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
}

.h1-heading {
  color: var(--black-100);
  /* H1 Heading */
  font-family: "Nunito Sans";
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px; /* 114.286% */
}
.h5-heading {
  color: #000;
  /* H5 Heading */
  font-family: "Nunito Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
}

.text-big {
  font-family: "Nunito Sans";
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
}

.text {
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.text-min {
  color: #000;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.text-small {
  color: #fff;
  font-family: "Nunito Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 133.333% */
}

.action-gray {
  background: rgba(237, 237, 237, 1);
  padding: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
  }
  &_disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.text-gray {
  color: var(--black-60, #666);
}
.pagination {
  .med-pagination__btn {
    background-color: transparent !important;
  }
  .active {
    background-color: #fff !important;
  }
  .med-pagination__btn {
    border: 2px solid transparent;

    &:hover {
      border-radius: 80px;
      border: 2px solid var(--Grey-80, #d0d0d0);
    }
  }
}

.h8-text {
  color: #000;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
}

.med-multi-select__box {
  height: 32px;
  line-height: 32px;
  padding: 8px 12px;
}
.med-multi-select__title {
  display: block !important;
  line-height: 14px;
}

.med-select__box {
  height: 32px;
  line-height: 32px;
  padding: 8px 12px;
}
.med-select__title {
  display: block !important;
  line-height: 14px;
}

.med-select__title {
  font-family: e-Ukraine;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.spinner {
  width: 70px !important;
}

.action-button {
  display: flex;
  gap: 10px;
  border-radius: 8px;
  align-items: center;
  background: var(--Blue-20, #dbdbf2);
  display: flex;
  cursor: pointer;
  border: none;
  padding: 12px 16px;
  align-items: center;

  color: #000;
  text-align: center;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */

  &:hover {
    background: var(--Black-100, #000);
    color: #fff;
    span {
      color: #fff;
    }
  }


}
app-loader {
  width: 100%;
}

.h7-heading {
  /* H7 Heading */
  font-family: "Nunito Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
}

.normal-text {
  font-family: "Nunito Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 140% */
}

.h2-heading {
  color: #000;

  /* H2 Heading */
  font-family: "Nunito Sans";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 46px; /* 115% */
}

.h4-heading {
  color: #000;

  /* H4 Heading */
  font-family: "Nunito Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px; /* 121.429% */
}

.h6-heading {
  color: #000;
  /* H6 Heading */
  font-family: "Nunito Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
}

.block {
  background-color: #fff;
  border-radius: 12px;
  font-family: Nunito Sans;
  overflow: hidden;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

button.med-btn {
  font-family: "Nunito Sans" !important;
  font-weight: 700 !important;
}

.normal-padding {
  button.med-btn {
    padding: 12px 16px !important;
  }
}
.search-padding {
  button.med-btn {
    padding: 14px 16px !important;
  }
}
.square-padding {
  button.med-btn {
    padding: 12px !important;
  }
}

.h10-text {
  font-family: "Nunito Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.text-12 {
  color: var(--Black-100, #000);
  font-family: e-Ukraine;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
}

app-navigation-block {
  width: 50%;
}

.med-upload-file__input {
  height: 42px;
}

.action-black {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  gap: 10px;
  border-radius: 8px;
  cursor: pointer;
  background: rgba(41, 41, 41, 0.9);
  border: none;
  color: white;

  > :not(.not-fill) svg path {
    stroke: white;
  }
  > :not(.not-stroke) svg path {
    fill: white;
  }
}

.med-modal__content {
  padding: 0 !important;
  max-width: 100% !important;
}
.med-modal__close {
  display: none !important;
}

.med-accordion {
  border-radius: 6px;
  border: 1px solid var(--Grey-100, #c7c7c7);
  background: #fff;
  width: 744px;
}

.field {
  line-height: 23px;
  font-size: 14px;
}

.med-btn.gray.focus {
  --bg-color: var(--black-80) !important;
  --border-color: var(--black-80) !important;
  --text-color: var(--grey-20) !important;
  .icon svg path {
    fill: white;
  }
}

med-table {
  width: 100%;
}

@media (max-width: 1150px) {
  .additional {
    flex-direction: column;
    align-items: center;
    margin-left: 10px;

    app-navigation-block {
      width: 100%;
    }
  }
  .med-accordion {
    width: 100%;
  }
}

@media (max-width: 750px) {

  .h1-heading {
    /* M1 Heading */
    font-size: 28px;
    font-weight: 700;
    line-height: 34px; /* 121.429% */
  }
  .normal-text {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .h2-heading {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
  }
  .h4-heading {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px; /* 140% */
  }

  .text {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
  }

  .h7-heading {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  .h5-heading {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
  }

  .h8-text {
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
  }

  .h9-text {
    //styleName: H9 Text;
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
  }

  .field {
    line-height: 23px;
    font-size: 12px;
  }
  .text-big {
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
  }
}

.list-none {
  list-style: none !important;
}

.list-disc {
  list-style: disc !important;
}

.list-decimal {
  list-style: decimal !important;
}

.appearance-none {
  appearance: none !important;
}

.outline-none {
  outline: none !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.pointer-events-auto {
  pointer-events: auto !important;
}

.cursor-auto {
  cursor: auto !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-wait {
  cursor: wait !important;
}

.cursor-move {
  cursor: move !important;
}

.select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.select-text {
  -webkit-user-select: text !important;
  user-select: text !important;
}

.select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-10 {
  opacity: 0.1 !important;
}

.opacity-20 {
  opacity: 0.2 !important;
}

.opacity-30 {
  opacity: 0.3 !important;
}

.opacity-40 {
  opacity: 0.4 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-60 {
  opacity: 0.6 !important;
}

.opacity-70 {
  opacity: 0.7 !important;
}

.opacity-80 {
  opacity: 0.8 !important;
}

.opacity-90 {
  opacity: 0.9 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.reset {
  all: unset;
}

.transition-none {
  transition-property: none !important;
}

.transition-all {
  transition-property: all !important;
}

.transition-colors {
  transition-property: background-color, border-color, color !important;
}

.transition-transform {
  transition-property: transform !important;
}

.transition-duration-100 {
  transition-duration: 0.1s !important;
}

.transition-duration-150 {
  transition-duration: 0.15s !important;
}

.transition-duration-200 {
  transition-duration: 0.2s !important;
}

.transition-duration-300 {
  transition-duration: 0.3s !important;
}

.transition-duration-400 {
  transition-duration: 0.4s !important;
}

.transition-duration-500 {
  transition-duration: 0.5s !important;
}

.transition-duration-1000 {
  transition-duration: 1s !important;
}

.transition-duration-2000 {
  transition-duration: 2s !important;
}

.transition-duration-3000 {
  transition-duration: 3s !important;
}

.transition-linear {
  transition-timing-function: linear !important;
}

.transition-ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
}

.transition-ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
}

.transition-ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.transition-delay-100 {
  transition-delay: 0.1s !important;
}

.transition-delay-150 {
  transition-delay: 0.15s !important;
}

.transition-delay-200 {
  transition-delay: 0.2s !important;
}

.transition-delay-300 {
  transition-delay: 0.3s !important;
}

.transition-delay-400 {
  transition-delay: 0.4s !important;
}

.transition-delay-500 {
  transition-delay: 0.5s !important;
}

.transition-delay-1000 {
  transition-delay: 1s !important;
}

.translate-x-0 {
  transform: translate(0) !important;
}

.translate-x-100 {
  transform: translate(100%) !important;
}

.-translate-x-100 {
  transform: translate(-100%) !important;
}

.translate-y-0 {
  transform: translateY(0) !important;
}

.translate-y-100 {
  transform: translateY(100%) !important;
}

.-translate-y-100 {
  transform: translateY(-100%) !important;
}

@media screen and (min-width: 576px) {
  .sm\:translate-x-0 {
    transform: translate(0) !important;
  }

  .sm\:translate-x-100 {
    transform: translate(100%) !important;
  }

  .sm\:-translate-x-100 {
    transform: translate(-100%) !important;
  }

  .sm\:translate-y-0 {
    transform: translateY(0) !important;
  }

  .sm\:translate-y-100 {
    transform: translateY(100%) !important;
  }

  .sm\:-translate-y-100 {
    transform: translateY(-100%) !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:translate-x-0 {
    transform: translate(0) !important;
  }

  .md\:translate-x-100 {
    transform: translate(100%) !important;
  }

  .md\:-translate-x-100 {
    transform: translate(-100%) !important;
  }

  .md\:translate-y-0 {
    transform: translateY(0) !important;
  }

  .md\:translate-y-100 {
    transform: translateY(100%) !important;
  }

  .md\:-translate-y-100 {
    transform: translateY(-100%) !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:translate-x-0 {
    transform: translate(0) !important;
  }

  .lg\:translate-x-100 {
    transform: translate(100%) !important;
  }

  .lg\:-translate-x-100 {
    transform: translate(-100%) !important;
  }

  .lg\:translate-y-0 {
    transform: translateY(0) !important;
  }

  .lg\:translate-y-100 {
    transform: translateY(100%) !important;
  }

  .lg\:-translate-y-100 {
    transform: translateY(-100%) !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:translate-x-0 {
    transform: translate(0) !important;
  }

  .xl\:translate-x-100 {
    transform: translate(100%) !important;
  }

  .xl\:-translate-x-100 {
    transform: translate(-100%) !important;
  }

  .xl\:translate-y-0 {
    transform: translateY(0) !important;
  }

  .xl\:translate-y-100 {
    transform: translateY(100%) !important;
  }

  .xl\:-translate-y-100 {
    transform: translateY(-100%) !important;
  }
}

.rotate-45 {
  transform: rotate(45deg) !important;
}

.-rotate-45 {
  transform: rotate(-45deg) !important;
}

.rotate-90 {
  transform: rotate(90deg) !important;
}

.-rotate-90 {
  transform: rotate(-90deg) !important;
}

.rotate-180 {
  transform: rotate(180deg) !important;
}

.-rotate-180 {
  transform: rotate(-180deg) !important;
}

@media screen and (min-width: 576px) {
  .sm\:rotate-45 {
    transform: rotate(45deg) !important;
  }

  .sm\:-rotate-45 {
    transform: rotate(-45deg) !important;
  }

  .sm\:rotate-90 {
    transform: rotate(90deg) !important;
  }

  .sm\:-rotate-90 {
    transform: rotate(-90deg) !important;
  }

  .sm\:rotate-180 {
    transform: rotate(180deg) !important;
  }

  .sm\:-rotate-180 {
    transform: rotate(-180deg) !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:rotate-45 {
    transform: rotate(45deg) !important;
  }

  .md\:-rotate-45 {
    transform: rotate(-45deg) !important;
  }

  .md\:rotate-90 {
    transform: rotate(90deg) !important;
  }

  .md\:-rotate-90 {
    transform: rotate(-90deg) !important;
  }

  .md\:rotate-180 {
    transform: rotate(180deg) !important;
  }

  .md\:-rotate-180 {
    transform: rotate(-180deg) !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:rotate-45 {
    transform: rotate(45deg) !important;
  }

  .lg\:-rotate-45 {
    transform: rotate(-45deg) !important;
  }

  .lg\:rotate-90 {
    transform: rotate(90deg) !important;
  }

  .lg\:-rotate-90 {
    transform: rotate(-90deg) !important;
  }

  .lg\:rotate-180 {
    transform: rotate(180deg) !important;
  }

  .lg\:-rotate-180 {
    transform: rotate(-180deg) !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:rotate-45 {
    transform: rotate(45deg) !important;
  }

  .xl\:-rotate-45 {
    transform: rotate(-45deg) !important;
  }

  .xl\:rotate-90 {
    transform: rotate(90deg) !important;
  }

  .xl\:-rotate-90 {
    transform: rotate(-90deg) !important;
  }

  .xl\:rotate-180 {
    transform: rotate(180deg) !important;
  }

  .xl\:-rotate-180 {
    transform: rotate(-180deg) !important;
  }
}

.origin-center {
  transform-origin: center !important;
}

.origin-top {
  transform-origin: top !important;
}

.origin-top-right {
  transform-origin: top right !important;
}

.origin-right {
  transform-origin: right !important;
}

.origin-bottom-right {
  transform-origin: bottom right !important;
}

.origin-bottom {
  transform-origin: bottom !important;
}

.origin-bottom-left {
  transform-origin: bottom left !important;
}

.origin-left {
  transform-origin: left !important;
}

@media screen and (min-width: 576px) {
  .sm\:origin-center {
    transform-origin: center !important;
  }

  .sm\:origin-top {
    transform-origin: top !important;
  }

  .sm\:origin-top-right {
    transform-origin: top right !important;
  }

  .sm\:origin-right {
    transform-origin: right !important;
  }

  .sm\:origin-bottom-right {
    transform-origin: bottom right !important;
  }

  .sm\:origin-bottom {
    transform-origin: bottom !important;
  }

  .sm\:origin-bottom-left {
    transform-origin: bottom left !important;
  }

  .sm\:origin-left {
    transform-origin: left !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:origin-center {
    transform-origin: center !important;
  }

  .md\:origin-top {
    transform-origin: top !important;
  }

  .md\:origin-top-right {
    transform-origin: top right !important;
  }

  .md\:origin-right {
    transform-origin: right !important;
  }

  .md\:origin-bottom-right {
    transform-origin: bottom right !important;
  }

  .md\:origin-bottom {
    transform-origin: bottom !important;
  }

  .md\:origin-bottom-left {
    transform-origin: bottom left !important;
  }

  .md\:origin-left {
    transform-origin: left !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:origin-center {
    transform-origin: center !important;
  }

  .lg\:origin-top {
    transform-origin: top !important;
  }

  .lg\:origin-top-right {
    transform-origin: top right !important;
  }

  .lg\:origin-right {
    transform-origin: right !important;
  }

  .lg\:origin-bottom-right {
    transform-origin: bottom right !important;
  }

  .lg\:origin-bottom {
    transform-origin: bottom !important;
  }

  .lg\:origin-bottom-left {
    transform-origin: bottom left !important;
  }

  .lg\:origin-left {
    transform-origin: left !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:origin-center {
    transform-origin: center !important;
  }

  .xl\:origin-top {
    transform-origin: top !important;
  }

  .xl\:origin-top-right {
    transform-origin: top right !important;
  }

  .xl\:origin-right {
    transform-origin: right !important;
  }

  .xl\:origin-bottom-right {
    transform-origin: bottom right !important;
  }

  .xl\:origin-bottom {
    transform-origin: bottom !important;
  }

  .xl\:origin-bottom-left {
    transform-origin: bottom left !important;
  }

  .xl\:origin-left {
    transform-origin: left !important;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes scalein {
  0% {
    opacity: 0;
    transform: scaleY(0.8);
    transition:
      transform 0.12s cubic-bezier(0, 0, 0.2, 1),
      opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }

  to {
    opacity: 1;
    transform: scaleY(1);
  }
}

@keyframes slidedown {
  0% {
    max-height: 0;
  }

  to {
    max-height: 100%;
  }
}

@keyframes slideup {
  0% {
    max-height: 1000px;
  }

  to {
    max-height: 0;
  }
}

@keyframes fadeinleft {
  0% {
    opacity: 0;
    transform: translate(-100%);
    transition:
      transform 0.12s cubic-bezier(0, 0, 0.2, 1),
      opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }

  to {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes fadeoutleft {
  0% {
    opacity: 1;
    transform: translate(0);
    transition:
      transform 0.12s cubic-bezier(0, 0, 0.2, 1),
      opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }

  to {
    opacity: 0;
    transform: translate(-100%);
  }
}

@keyframes fadeinleftease {
  0% {
    opacity: 0;
    transform: translate(-20%);
    transition:
      transform 0.12s cubic-bezier(0, 0, 0.2, 1),
      opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }

  to {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes fadeoutleftease {
  0% {
    opacity: 1;
    transform: translate(0);
    transition:
      transform 0.12s cubic-bezier(0, 0, 0.2, 1),
      opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }

  to {
    opacity: 0;
    transform: translate(-20%);
  }
}

@keyframes fadeinright {
  0% {
    opacity: 0;
    transform: translate(100%);
    transition:
      transform 0.12s cubic-bezier(0, 0, 0.2, 1),
      opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }

  to {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes fadeoutright {
  0% {
    opacity: 1;
    transform: translate(0);
    transition:
      transform 0.12s cubic-bezier(0, 0, 0.2, 1),
      opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }

  to {
    opacity: 0;
    transform: translate(100%);
  }
}

@keyframes fadeinrightease {
  0% {
    opacity: 0;
    transform: translate(20%);
    transition:
      transform 0.12s cubic-bezier(0, 0, 0.2, 1),
      opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }

  to {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes fadeoutrightease {
  0% {
    opacity: 1;
    transform: translate(0);
    transition:
      transform 0.12s cubic-bezier(0, 0, 0.2, 1),
      opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }

  to {
    opacity: 0;
    transform: translate(20%);
  }
}

@keyframes fadeinup {
  0% {
    opacity: 0;
    transform: translateY(-100%);
    transition:
      transform 0.12s cubic-bezier(0, 0, 0.2, 1),
      opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeoutup {
  0% {
    opacity: 1;
    transform: translateY(0);
    transition:
      transform 0.12s cubic-bezier(0, 0, 0.2, 1),
      opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }

  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}

@keyframes fadeindown {
  0% {
    opacity: 0;
    transform: translateY(100%);
    transition:
      transform 0.12s cubic-bezier(0, 0, 0.2, 1),
      opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeoutdown {
  0% {
    opacity: 1;
    transform: translateY(0);
    transition:
      transform 0.12s cubic-bezier(0, 0, 0.2, 1),
      opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }

  to {
    opacity: 0;
    transform: translateY(100%);
  }
}

@keyframes animate-width {
  0% {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes flip {
  0% {
    transform: perspective(2000px) rotateX(-100deg);
  }

  to {
    transform: perspective(2000px) rotateX(0);
  }
}

@keyframes flipleft {
  0% {
    transform: perspective(2000px) rotateY(-100deg);
    opacity: 0;
  }

  to {
    transform: perspective(2000px) rotateY(0);
    opacity: 1;
  }
}

@keyframes flipright {
  0% {
    transform: perspective(2000px) rotateY(100deg);
    opacity: 0;
  }

  to {
    transform: perspective(2000px) rotateY(0);
    opacity: 1;
  }
}

@keyframes flipup {
  0% {
    transform: perspective(2000px) rotateX(-100deg);
    opacity: 0;
  }

  to {
    transform: perspective(2000px) rotateX(0);
    opacity: 1;
  }
}

@keyframes zoomin {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomindown {
  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
  }

  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
  }
}

@keyframes zoominleft {
  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
  }
}

@keyframes zoominright {
  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
  }
}

@keyframes zoominup {
  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
  }

  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
  }
}

.fadein {
  animation: fadein 0.15s linear;
}

.fadeinleftease {
  animation: fadeinleftease 0.15s linear;
}
.fadeoutleftease {
  animation: fadeoutleftease 0.15s linear;
}

.fadeinrightease {
  animation: fadeinrightease 0.15s linear;
}
.fadeoutrightease {
  animation: fadeoutrightease 0.15s linear;
}

.fadeout {
  animation: fadeout 0.15s linear;
}

.slidedown {
  animation: slidedown 0.45s ease-in-out;
}

.slideup {
  animation: slideup 0.45s cubic-bezier(0, 1, 0, 1);
}

.scalein {
  animation: scalein 0.15s linear;
}

.fadeinleft {
  animation: fadeinleft 0.15s linear;
}

.fadeoutleft {
  animation: fadeoutleft 0.15s linear;
}

.fadeinright {
  animation: fadeinright 0.15s linear;
}

.fadeoutright {
  animation: fadeoutright 0.15s linear;
}

.fadeinup {
  animation: fadeinup 0.15s linear;
}

.fadeoutup {
  animation: fadeoutup 0.15s linear;
}

.fadeindown {
  animation: fadeindown 0.15s linear;
}

.fadeoutdown {
  animation: fadeoutdown 0.15s linear;
}

.animate-width {
  animation: animate-width 1s linear;
}

.flip {
  backface-visibility: visible;
  animation: flip 0.15s linear;
}

.flipup {
  backface-visibility: visible;
  animation: flipup 0.15s linear;
}

.flipleft {
  backface-visibility: visible;
  animation: flipleft 0.15s linear;
}

.flipright {
  backface-visibility: visible;
  animation: flipright 0.15s linear;
}

.zoomin {
  animation: zoomin 0.15s linear;
}

.zoomindown {
  animation: zoomindown 0.15s linear;
}

.zoominleft {
  animation: zoominleft 0.15s linear;
}

.zoominright {
  animation: zoominright 0.15s linear;
}

.zoominup {
  animation: zoominup 0.15s linear;
}

.animation-duration-100 {
  animation-duration: 0.1s !important;
}

.animation-duration-150 {
  animation-duration: 0.15s !important;
}

.animation-duration-200 {
  animation-duration: 0.2s !important;
}

.animation-duration-300 {
  animation-duration: 0.3s !important;
}

.animation-duration-400 {
  animation-duration: 0.4s !important;
}

.animation-duration-500 {
  animation-duration: 0.5s !important;
}

.animation-duration-700 {
  animation-duration: 0.7s !important;
}

.animation-duration-1000 {
  animation-duration: 1s !important;
}
.animation-duration-1500 {
  animation-duration: 1.5s !important;
}

.animation-duration-2000 {
  animation-duration: 2s !important;
}

.animation-duration-3000 {
  animation-duration: 3s !important;
}

.animation-delay-100 {
  animation-delay: 0.1s !important;
}

.animation-delay-150 {
  animation-delay: 0.15s !important;
}

.animation-delay-200 {
  animation-delay: 0.2s !important;
}

.animation-delay-300 {
  animation-delay: 0.3s !important;
}

.animation-delay-400 {
  animation-delay: 0.4s !important;
}

.animation-delay-500 {
  animation-delay: 0.5s !important;
}

.animation-delay-800 {
  animation-delay: 0.8s !important;
}

.animation-delay-1000 {
  animation-delay: 1s !important;
}

.animation-iteration-1 {
  animation-iteration-count: 1 !important;
}

.animation-iteration-2 {
  animation-iteration-count: 2 !important;
}

.animation-iteration-infinite {
  animation-iteration-count: infinite !important;
}

.animation-linear {
  animation-timing-function: linear !important;
}

.animation-ease-in {
  animation-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
}

.animation-ease-out {
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
}

.animation-ease-in-out {
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.animation-fill-none {
  animation-fill-mode: none !important;
}

.animation-fill-forwards {
  animation-fill-mode: forwards !important;
}

.animation-fill-backwards {
  animation-fill-mode: backwards !important;
}

.animation-fill-both {
  animation-fill-mode: both !important;
}

.main-loader.loader {
  top: 155px;
}

.loader {
  position: fixed;
  --size: 280px;
  width: 100%;
  max-width: 1905px;
  height: calc(100vh - 200px);
  top: 200px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  z-index: 100;
  border-radius: 12px;

  &__icon {
    width: var(--size);
    position: relative;
    display: flex;
    align-items: center;
    height: 250px;
    justify-content: center;
    border-radius: 50%;
    border: 4px solid var(--purple-5);
  }

  &__progress {
    position: absolute;
    top: -4px;
    left: -4px;
    display: inline-block;
    width: 103%;
    height: auto;
    animation: spin 2s linear infinite;
  }
}
.large-1 .loader {
  top: 227px;
  height: calc(100vh - 227px);
}

.global-loader {
  --size: 280px;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  z-index: 9999;
  border-radius: 12px;

  &__icon {
    width: var(--size);
    height: var(--size);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 4px solid var(--purple-5);
  }

  &__progress {
    position: absolute;
    top: -4px;
    left: -4px;
    display: inline-block;
    width: 103%;
    height: auto;
    animation: spin 2s linear infinite;
  }
}

.orders .loader {
  transform: translate(0);
  position: static;
  width: 100%;
  max-height: 1700px;
  height: calc(100vh - 200px);
  //min-height: 500px;
}

.faq .loader {
  position: static;
  width: 100%;
  max-height: 1700px;
  height: calc(100vh - 200px);
  //min-height: 500px;
}

.better-view {
  app-pagination,
  img,
  .chip,
  .active,
  .img-div,
  a,
  svg,
  button,
  .contacts__item,
  canvas {
    filter: grayscale(100%);

    ::ng-deep iframe {
      filter: grayscale(100%);
    }
  }
  .img-div-white {
    color: white !important;
  }
  .header__logo {
    span {
      max-width: 170px!important;
    }
  }
}

@media (max-width: 1902px) {
  .loader {
    top: 268px;
    width: 100%;
    height: calc(100vh - 268px);
    left: 16px;
    transform: translateX(0);
  }
  .main-loader.loader {
    top: 228px;
    height: calc(100vh - 228px);
  }

  body {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 1470px) {
  .loader {
    top: 208px;
    height: calc(100vh - 208px);
    width: calc(100% - 8px);
    left: 4px;
  }
  .main-loader.loader {
    top: 163px;
    height: calc(100vh - 163px);
  }
}

@media (max-width: 1250px) {
  .loader {
    top: 155px;
    height: calc(100vh - 155px);
  }
  .main-loader.loader {
    top: 112px;
    height: calc(100vh - 112px);
  }
}

@media (max-width: 750px) {
  .loader {
    top: 150px;
    height: calc(100vh - 150px);
    width: calc(100% - 8px);
    left: 4px;
  }
  .main-loader.loader {
    top: 106px;
    height: calc(100vh - 106px);
  }

  .ng-image-slider .ng-image-slider-container .main .next {
    right: 10px !important;
  }
  .ng-image-slider .ng-image-slider-container .main .prev {
    left: 10px !important;
  }
}

@media (max-width: 650px) {
  .disable-right-min.fadeinrightease {
    animation: none;
  }
  .disable-right-min.fadeoutrightease {
    animation: none;
  }
  .disable-up-down-animation-for-min {
    .fadeinup {
      animation: none !important;
    }
    .fadeoutup {
      animation: none !important;
    }
    .fadeindown {
      animation: none !important;
    }
    .fadeoutdown {
      animation: none !important;
    }
  }

  .fadeinrighteasemin {
    animation: fadeinrightease 0.15s linear;
  }
  .fadeinlefteasemin {
    animation: fadeinleftease 0.15s linear;
  }
  .fadeoutrighteasemin {
    animation: fadeoutrightease 0.15s linear;
  }
  .fadeoutlefteasemin {
    animation: fadeoutleftease 0.15s linear;
  }
}

.preload-image {
  opacity: 0;
  height: 0;
}

.large-1 {
  .action-button {
    font-size: 16px;
  }

  header.header {
    flex-direction: column;
    align-items: start;
    height: auto;
    gap: 8px;
  }
  header > .header__actions {
    align-self: end;
  }

  .med-btn {
    font-size: 16px !important;
  }
  .about__infos-item {
    font-size: 16px !important;
  }
  .h9-text {
    font-size: 16px;
  }

  .h1-heading {
    font-size: 58px;
  }
  .h5-heading {
    font-size: 26px;
  }

  .text-big {
    font-size: 34px;
  }

  .text {
    font-size: 18px;
  }

  .text-min {
    font-size: 16px;
  }

  .text-small {
    font-size: 14px;
  }
  .h8-text {
    font-size: 18px;
  }

  .h7-heading {
    font-size: 20px;
  }

  .normal-text {
    font-size: 22px;
  }

  .h2-heading {
    font-size: 42px;
  }

  .h4-heading {
    font-size: 30px;
  }

  .h6-heading {
    font-size: 22px;
  }

  .h10-text {
    font-size: 14px;
  }

  .text-12 {
    font-size: 14px;
  }
}

.large-2 {
  .action-button {
    font-size: 18px;
  }
  .med-btn {
    font-size: 18px !important;
  }
  .about__infos-item {
    font-size: 18px !important;
  }
  .h9-text {
    font-size: 18px;
  }

  .h1-heading {
    font-size: 60px;
  }
  .h5-heading {
    font-size: 28px;
  }

  .text-big {
    font-size: 36px;
  }

  .text {
    font-size: 20px;
  }

  .text-min {
    font-size: 18px;
  }

  .text-small {
    font-size: 16px;
  }
  .h8-text {
    font-size: 20px;
  }

  .h7-heading {
    font-size: 22px;
  }

  .normal-text {
    font-size: 24px;
  }

  .h2-heading {
    font-size: 44px;
  }

  .h4-heading {
    font-size: 32px;
  }

  .h6-heading {
    font-size: 24px;
  }

  .h10-text {
    font-size: 16px;
  }

  .text-12 {
    font-size: 16px;
  }
}

.large-3 {
  .action-button {
    font-size: 20px;
  }
  .med-btn {
    font-size: 20px !important;
    line-height: 24px;
  }
  .about__infos-item {
    font-size: 20px !important;
    line-height: 24px;
  }
  .h9-text {
    font-size: 20px;
    line-height: 24px;
  }

  .h1-heading {
    font-size: 62px;
    line-height: 66px;
  }
  .h5-heading {
    font-size: 30px;
    line-height: 34px;
  }

  .text-big {
    font-size: 38px;
    line-height: 42px;
  }

  .text {
    font-size: 22px;
    line-height: 26px;
  }

  .text-min {
    font-size: 20px;
    line-height: 24px;
  }

  .text-small {
    font-size: 18px;
    line-height: 22px;
  }
  .h8-text {
    font-size: 22px;
    line-height: 26px;
  }

  .h7-heading {
    font-size: 24px;
    line-height: 28px;
  }

  .normal-text {
    font-size: 26px;
    line-height: 30px;
  }

  .h2-heading {
    font-size: 46px;
    line-height: 50px;
  }

  .h4-heading {
    font-size: 34px;
    line-height: 38px;
  }

  .h6-heading {
    font-size: 26px;
    line-height: 30px;
  }

  .h10-text {
    font-size: 18px;
    line-height: 22px;
  }

  .text-12 {
    font-size: 18px;
    line-height: 22px;
  }
}
